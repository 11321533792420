.editor{
    width: 100%;
    border: solid 0.5pt black;
    padding: 10px;
    &__toolbar{
        padding: 2px;
    }

    &__text{
        border: solid 0.5pt black;
        background: #f3f3f3;
    }
}