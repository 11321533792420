*{
    box-sizing: border-box;
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f100;
  }


  ::-webkit-scrollbar-thumb {
    background: #888;
  }


  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  }
  
  body {
    margin: 0 auto;
    max-width: 1440px;
  }

  .warpper{
    height: 100%;
    &__body{
      height: 100%;
      overflow: hidden;
    }
  }
  .content{
    &__main{
      height: 100svh ;
    }
    &__recettes{
      align-content: start;
      margin-bottom: 15px;
    }
  }

  a{
    text-decoration: none;
    color: black;
    size: 16;
  }

  .titel{
    width: 100%;
    text-align: center;
  }

  .overflow{
    &--hidden{
      overflow: hidden;
    }
  }

  .img{
    &--expand{
      width: 17px;
    }
  }

  table{
    border-collapse: collapse;
    border: solid 0.5pt;
    margin: 5px;
  }

  td, th{
    padding: 5px;
    text-align: center;
    border: solid 0.1pt;
  }

  .relative{
    position: relative;
  }

  .align{
    &--centre{
      align-self: center;
    }
  }

  .searchBar{
    width: initial !important;
  }