.group-form{
    border-radius: 10px;
    padding: 10px;
    border: 0.1pt solid #dadce0;
    margin: 5px;
    max-width: 100%;
    &__titel{
        margin-bottom: 5px;
    }
    &--50{
        width: 50%;
    }
    &__group{
        margin: 5px;
        & label{
            margin-right: 5px;
        }
        & div{
            flex-grow: 2;
        }
        & input[type="number"]{
            width: 50px;
        }
    }
    &__field{
        position: relative;
        height: 56px;
        padding-left: 15px;
        margin-bottom:  20px;
        &__name{
            position: relative;
            display: inline-block;
            text-overflow: clip;
            white-space: nowrap;
            font-size: 1rem;
            transform: translateY(-50%);
            left: 4px; 
            &--empty{
                top: 50%;
            }
        }
        &__err{
            position: relative;
            display: inline-block;
            text-overflow: clip;
            white-space: nowrap;
            font-size: 1rem;
            transform: translateY(50%);
            color: red; 
            &--empty{
                top: 50%;
            }
        }

        &__value{
           width: 100%;
            border: none;
            height: 100%;
            z-index: 1;
            outline: none;
            align-content: center; 
        }
        &__border{
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            z-index: 2;
            pointer-events: none;
            transition: border-color 250ms ease-out 100ms;
            transition: color 250ms ease-out 100ms;
            &--err{
                border-color: red;
            }
            &  span{
                border-color: inherit;
            }
            &--left{
                width: 15px;
                border: solid 0.1pt;
                
                flex-grow: 0;
                border-right: none;
                border-radius: 5px 0px 0px 5px;  
            }
            &--middel{
                /* border-bottom: solid 0.1pt; */
                width: max-content;
                text-align: center;
                flex-grow: 0;
                padding-right: 10px;
                box-sizing: content-box;
                &--empty{
                    border-top: solid 0.1pt;
                }
            }
            &--right{
                flex-grow: 1;
                border: solid 0.1pt;
                border-left: none;
                border-radius: 0px 5px 5px 0px;
                &--err{
                    flex-grow: 1;
                    
                }
                &__left{
                    flex-grow: 1;
                    border: solid 0.1pt;
                    border-right: none;
                    border-left: none;
                    height: 100%;
                }
                &__middel{
                    border-top: solid 0.1pt;
                    width: max-content;
                    text-align: center;
                    flex-grow: 0;
                    padding-right: 10px;
                    box-sizing: content-box;
                    height: 100%;
                }
                &__right{
                    flex-grow: 1;
                    border: solid 0.1pt;
                    border-left: none;
                    border-bottom: none;
                    border-radius: 0px 5px 5px 0px;
                    height: 100%; 
                }
            }
            &--botum{
                position: absolute;
                bottom: 0;
                padding-left: 15px;
                flex-wrap: nowrap;
                padding-right: 3px;
                &__left{
                    border-bottom: solid 0.1pt;
                    flex-grow: 1;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    height: 18px;
                }
                &__midel{
                    &--err{
                        padding: 0 5px;
                        width: max-content;
                    }
                }
                &__right{
                    width: 15px;
                    border-bottom: solid 0.1pt;
                    height: 18px;
                }
            }
        }
    }
    &__options{
        margin: 10px 0;
        &__option{
            font-size: 14px;
            &:hover{
                color: lightgray;
            }
        }
    }
    &__search{
        & input{
            flex-grow: 1;
            margin: 5px;
        }
    }
}