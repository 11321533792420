.modal{
    z-index: 3;
    position: fixed;
    top: 0;
    right: 0;
    background: #1c1c1ce8;
    height: 100svh;
    &--relative{
        position: absolute;
        height: 100svh;
    }
    &__body{
        max-width: 80%;
        height: 100svh;
        &--centre{
            height: max-content;
        }
        &--small{
            width: max-content;
        }
       & > *{
        background: white; 
       }
    }
    
    

}