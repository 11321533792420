.loading{
    &__img{
        animation: spin 4s infinite linear;
         width: 50px !important;
    }
}

@keyframes spin {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(359deg);
    }
}