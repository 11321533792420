.ingredient{
    flex-wrap: nowrap;
    border: 0.5pt solid black;
    width: 80%;
    height: 95px;
    margin: 10px 0;
    padding: 5px;
    & input{
        width: 100%;
    }
    & img{
        width: 100px;
    }
    &__new{
        width: 30%;
        border: solid black 0.5pt;
        background: rgb(220, 220, 220);
        border-radius: 10px;
        & .titre{
            border-bottom: solid black 0.5pt;
            width: 100%;
            text-align: center;
            background: rgb(152, 226, 202);
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
            margin-bottom: 15px;   
        }
        & input{
            margin-bottom: 15px;
        }
        & img{
            width: 95px;
        }
    }
    &__remove{
        font-size: medium;
        position: absolute;
    }
    &--rond{
        border: none;
        width: 100px;
        height: 109px;
        border-radius: 50%;
        flex-wrap: wrap;
        margin: 10px;
        & img{
            width: 50px;
        }

        & .ingredient__quantity, .ingredient__unit{
            font-size: 10px;
            width: max-content;
            margin: 0;
        }
        

        & .ingredient__nom{
            width: 100%;
            word-wrap: break-word;
            margin: 0;
        }
    }
    &--liste{
        width: 80%;
        height: 20px;
        & img{
            width: 15px;
            margin: 3px;
        }
    }
    &__liste{
        border: solid black 0.5pt;
        background-color: gray;
        width: 50%;
        z-index: 1;
        position: absolute;
        right: 10px;
    }
    &__nom{
        width: 40%;
        text-align: center;
        margin: 2.5px;
    }
    &__quantity{
        flex-grow: 0.5;
        text-align: center;
        flex-shrink: 4;
        margin: 2.5px;
    }
    &__unit{
        flex-grow: 1;
        flex-shrink: 4;
        text-align: center;
        margin: 2.5px;
    }
    &__state{
        flex-grow: 1;
        &[data-state='true']:before{
            content: url("../../../public/ok.png");
        }
        &[data-state='3']:before{
            content: url("../../../public/sync.png");
            
        }
    }
}