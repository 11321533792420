@import "base/base";
@import "Layout/Layout";
@import "Composant/Composant";
@import "Pages/Pages";
@import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  width: 100%;
  height: 100svh;
  overflow: hidden;
}



