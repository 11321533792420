.custumSelect{
    height: 100%;
    padding: 10px;
    &__warper{
        background: white;
        box-shadow: black 0px 0px 7px;
        height: 100%;
        overflow: scroll;
        position: absolute;
        width: max-content;
        height: auto;
        &--relative{
            position: absolute;
            z-index: 10;
            bottom: 55px;
            height: max-content;
            box-shadow: 0px 0px 5px 0px;
            background: white;
            border: solid 0.1pt;
            border-radius: 5px;
            @media (orientation:portrait) and (width:360px){
                bottom: 45px;
            }
            &--bottom{
                bottom: unset;
                @media (orientation:portrait) and (width:360px){
                    bottom: unset;
                }
            }
        }
    }
    &__option{
        padding: 1px;
        transition: background-color 250ms ease-out 100ms;
        cursor: pointer;
        &:hover{
            background-color: lightblue;
        }
        &--relative{
            border: 0.1pt solid;
            border-left: none;
            border-right: none;
            padding: 5px;
        }
    }
}   