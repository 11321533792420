.semaine{
    margin-bottom: 10px;
    width: 100%;
    height: 120px;
    flex-wrap: nowrap;
    &__numero{
        cursor: pointer;
        width: 85px;
        height: 85px;
        font-weight: bold;
        flex-shrink: 0;
        font-size: 30px;
        text-align: center;
        padding: 9px;
        border-radius: 5px;
        margin: 5px;
        background: aliceblue;
        &--active{
            background: #00FF19;
            color: white;
        }
        &--home{
            background: #10bd21;
            color: black;
            height: 40px;
            width: 40px;
            font-size: 15px;
        }
        &--abr{
            font-size: 0.5em;
        }
    }
}
