.liste-de-course{
    
    &__content{
        align-items: start;
        align-content: start;
        
    }
    &--home{
        height: 100%;
        overflow: hidden;
        overflow: hidden;
        & .liste-de-course__content{
            max-height: 1000px;
            overflow: auto;
        }
        @media screen and (max-width:800px) {
            width: 100%;
        }
    }
}