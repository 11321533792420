.menu{
    &__card{
        padding: 5px;
        margin: 10px 0;
    }
    &__home{
        flex-basis: 10px;
        flex-grow: 1;
        @media screen and (max-width: 800px) {
            width: 100%;
        }
    }
    &__content{
        flex-basis: 10px;
        flex-grow: 1;
    }
    &__ruban{
        margin: 25px 0;
        align-items: center;
        &__info{
            width: max-content;
            margin: 0 10px;
            & span{
                margin:0 20px
            }
        }
        &__action{
            width: max-content;
            margin: 0 10px;
            & img{
                margin: 0 20px;
                cursor: pointer;
            }

        }
    }
}