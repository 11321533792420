.btn{
    user-select: none;
    padding:  5px;
    cursor: pointer;
    transition: background-color 250ms ease-out 100ms;
    border: none;
    border-radius: 10px;
    &:hover{
        background-color: #888;
    }
    &:disabled{
        &:hover{
            background-color: inherit;
            cursor: not-allowed; 
        }
    }
    &--blue{
        background-color: #28b8db;
        &:hover{
            background-color: #386fdd;
        }
    }

    &--green{
        background-color: #26e455;
        &:hover{
            background-color: #33ce25;
        }
    }

    &--light{
        &:hover{
            background-color: #bdc1c9;
        }
    }
    &--top-bar{
        width:15px;
	    height:15px;
	    border:none;
	    background:white;
        padding: 0;
    }
    &--round{
        padding: 0;
        width: 30px;
        height: 30px;
        border: solid 0.1px;
        border-radius: 50%;
        & img{
            width: 100%;        
        }
    }
    &--add{
        background-color: transparent;
        border: none;
        border-radius: 50%;
        padding: 0;
        width: 30px;
        height: 30px;
        & img{
            width: 100%;
        }
    }
    &--big{
        margin: 5px;
        padding: 10px;
        border: none;
        border-radius: 10px;
        width: 100%;
        text-align: center;
    }
    &--nav{
        padding: 2px;
    }
    &--content{
        width: max-content;
        padding: 0;
        border: none;
        background-color: white;
    }
    &--transparent{
        background-color: transparent;
        &:hover{
            background-color: transparent;
        }
    }
    &--page{
        min-width: 24px;
        text-align: center;
        padding: 2px;
        border: 0.1pt solid #ccc;
        margin: 0 2px;
        background: linear-gradient(#fafafa 0,#dcdcdc 100%);
        &--active{
            border-color: #888 #aaa #ccc;
            box-shadow: inset 0 1px 5px rgba(0,0,0,.25), 0 1px 0 #fff;
        }
    }
    &__bascule{
        position: relative;
        margin: 5px;
        width: 25px;
        height: 10px;
        &--true{
            background: green;
            &:hover{
                background: red;
            }
        }
        &--false{
            background: red;
            &:hover{
                background: green;
            }
        }
        
        &__cursor{
            position: absolute;
            top:-2.5px;
            height: 15px;
            width: 15px;
            border: solid black 0.1pt;
            border-radius: 100%;
            background-color: gray;
            &--true{
                right: 0;
            }
            &--false{
                left: 0;
            }
        }
    }
}