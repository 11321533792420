.header{
    height: 50px;
    background: #bad9ec ;
    margin-bottom: 10px;
}

.link{
    color: #052b77;
    &--active{
        color: #28b8db;
        font-size: 20px;
    }
}