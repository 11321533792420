.recette{
    border: solid 0.5pt black;
    border-radius: 5px;
    width: 45%;
    height: 400px;
    align-content: start;
    margin: 10px;
    //overflow: hidden;
    
    & img{
        width: 100%;
    }
    &__recente{
        
        @media screen and (max-width: 675px) {
            display: none;
        }
    }
    &__ruban{
        align-items: center;
        & img{
            width: 50px;
        }
    }
    &__img{
        width: 100%;
        & input{
            display: none;
        }
        &--cuisiner{
            grid-column: 1;
            grid-row: 1;
            padding-bottom: 0;
            @media screen and (max-width: 950px) {
                grid-row-end: 3;
            }
            @media screen and (max-width: 700px) {
                grid-row-end: 1;
                grid-column-end: 5;
            }
            @media screen and (max-width: 500px) {
                grid-row-end: 2;
                grid-column-end: 1;
            }
            & img{

                max-width: 250px;
            }
        }
        &--info{
            grid-column: 1/5;
            grid-row: 1/2;
            padding-bottom: 0;
            & img{
                max-width: 250px;
            }
        }
     }
    &__info{
        padding: 5px;
        overflow: hidden;
        height: 100%;
        &--home{
            position: absolute;
            bottom: 0;
            color: white;
            background: #8e8e8e26;
            font-weight: bolder;
            height: max-content;
            padding: 0;
        }
        &__tags{
            overflow-y: scroll;
            flex-wrap: nowrap;
            height: 55px;
            @media screen and (max-width: 500px) {
                height: 45px;
            }
         }
         &__option{
            &__select{
                background: white;
                box-shadow: black 0px 0px 7px;
                @media screen and (max-width: 700px) {
                    left: -75px;
            }
         }}
        &--cuisiner{
            flex-wrap: nowrap;
            grid-column: 2/5;
            grid-row: 1;
            @media screen and (max-width: 950px) {
                grid-row: 1/3;
            }
            @media screen and (max-width: 700px) {
                grid-row: 2/4;
                grid-column: 1/5;
            }
            @media screen and (max-width: 500px) {
                grid-row: 1/2;
                grid-column: 2/5;
                overflow: visible;
            }
            &__tag{
                min-height: 50px;
            }
            &__warpper{
                width: 90%;
                height: 100%;
            }
        }
        &--info{
            height: 100%;
            grid-column: 1/5;
            grid-row: 2/5;
            &__tag{
                height: fit-content;
            }
        }
    }
     &__tags{
        flex-basis: content;
        flex-shrink: 1;
     }
     &__etapes{
        &--cuisiner{
            grid-row: 2/9;
            grid-column: 2/5;
            @media screen and (max-width: 950px) {
                grid-row: 3/9;
            }
            @media screen and (max-width: 700px) {
                grid-row-start: 4;
            }
            @media screen and (max-width: 550px) {
                grid-row: 2/9;
                grid-column: 2/5;
            }
            
        }
     }
    &__etape{
        padding: 30px 0;
        width: 100%;
        max-width: 400px;
        & textarea{
            margin: 20px;
            width: 100%;
            resize: none;
            display: block;
            overflow: scroll;
        }
        &__img{
            width: 100%;
            padding: 15px;
            & input{
                display: none;
            }
         }
        
        &__nom{
            padding: 15px;
        }
        &--cuisiner{
            grid-column: 1/3;
            grid-row: 1/3;
        }
    }
    & h3, h4{
        width: 100%;
        text-align: center;
    }
    &--recente{
        height: max-content;
        position: relative;
        min-width: 250px;
        & img{
            width: 100%;
           
        }
        &--home{
            height: max-content;
            position: relative;
            min-width: 250px;
            @media screen and (max-width: 1200px) {
                width: 100%;
                max-width: 300px;
            }
        } 
    }

    &--info{
        height: 100vb;
        max-width: 300px;
    }

    &__duration{
        padding: 10px;
        display: none;
    }

    &__description{
        flex-grow: 1;
        flex-shrink: 1;
        padding: 10px;
        width: 100%;
        max-height: 110px;
        height: 110px;
        & h1{
            width: 100%;
            text-align: center;
        }
        &--info{
            flex-basis: 10px;
            flex-grow: 1;
            max-height: none;
        }
        &--modify{
            height: fit-content;
            overflow: hidden;

            & textarea{
                width: 100%;
                resize: none;
                display: block;
                overflow: hidden;
            }
        }
       
    }
    
    &__name{
        flex-basis: auto;
        flex-shrink: 1;
        max-width: 85%;
        text-align: center;
        padding: 5px 15px;
        font-weight: bold;
        height: 45px;
        &--card{
            flex-basis: auto;
            max-width: 85%;
        }
    }

    &__ingredients{
        flex-shrink: 1;
        height: 120px;
        &--cuisiner{
            height: 100%;
            grid-row: 2/9;
            grid-column: 1;
            @media screen and (max-width: 950px) {
                grid-row: 3/9;
            }
            @media screen and (max-width: 700px) {
                grid-row-start: 4;
            }
             @media screen and (max-width: 550px) {
                grid-row: 2/9;
                grid-column: 1/2;
            }

        }
        &--info{
            height: 100%;
            align-content: start;
            margin: 10px 0;
            grid-row: 5/9;
            grid-column: 1/5;
        }
    }

    &__wrapper{
        flex-shrink: 0.5;
        flex-basis: 70px;
        flex-grow: 1;
        height: 70px;
        flex-flow: wrap column;
        &--info{
            height: 100vb;
            padding: 10px;
        }
    }
    &--menu{
        max-width: 500px;
        width: 100%;
        height: max-content
    
    }
    &--info{
        width: 100%;
        height: 100%;
        padding: 20px;
        row-gap: 5px;
    }
    &--cuisiner{
        border: none;
        width: 100%;
        height: 100%;
        &--modify{
            flex-basis: 10px;
            flex-grow: 1;
            height: 10px;
        }
        
        & .recette__description{
            @media screen and (max-width: 500px) {
                display: none;
            }
        }
    }

    &__btn{
        &--info{
            grid-column: 1/5;
        }
    }
}