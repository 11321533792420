.sideBar{
    &__warpper{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
        background-color: #000000ab;
        z-index: 100;
    }
     &--active{
        max-width: 250px;
        background-color: white;
        position: absolute;
        right: 0;
        height: 100%;
     }
}